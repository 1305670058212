

.layout {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-width: 100vw
}

.leftSide { 
  grid-area: leftSide; 
  display: flex;
  gap: 0px;
  padding: 0px 8px 0px 8px;
  flex-wrap: wrap;
}


body, .body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: #2E2E30;
  border-color: #1D3C7F;
  font-weight: normal;
  font-size: 1.1em;
  width: 100vw;
}


.standardbodywrapper.desktop{
  display: flex;
  width: 100vw;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.standardbodywrapper.mobile {
  display: flex;
  width: 100vw;
  align-items: center;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}

.homebodywrapper.desktop{
  display: flex;
  width: 100vw;
  align-items: stretch;
  flex-direction: column;
  width: 100vw;
}

.homebodywrapper.mobile {
  display: flex;
  width: 100vw;
  align-items: stretch;
  flex-direction: column;
  width: 100vw;
}



.footer { 
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  grid-area: footer; 
  padding-top: 40px;
  padding-bottom: 22px;
  float: bottom;
  width: 100vw;
  background-color: #242323;
  color: #E7E8F0;
}

.footer.desktop {
  flex-direction: row;
}

.footer.mobile {
  flex-direction: column;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  background-color: #FFFFFF;
}

.header { 
  text-align: center;
  color: #A0CCD4;

  padding: 10px;
  position: sticky;
  width: 100vw;


}
.header.ninatoka {
  font-family: 'Arizonia', Helvetica, Arial, sans-serif;
  font-size: 3em;

}
.header.tcm {
  font-family: 'Paprika', Helvetica, Arial, sans-serif;
  font-size: 3em;
  font-weight: bold;

  background: rgb(14,49,55);
  background: linear-gradient(99deg, rgba(14,49,55,1) 64%, rgba(46,89,53,1) 100%);
}
.navigator { 
  grid-area: navigator;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex-wrap: wrap;
}

.title { 
   grid-area: title;     
   align-self: flex-start;
   margin: 0;
   padding: 0;
   max-width: 100vw
}
.title.ninatoka {
  font-family: 'Open Sans', 'Courier New', sans-serif;
}
.title.tcm {
  font-family: 'Paprika', Helvetica, Arial, sans-serif;
}



.statsPage {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  margin-left: 10px;
  margin-right: 10px;
  max-width: 100%
}

.learnPageWrapper.desktop {
    display: flex;
    flex-direction: column;
    width: 85vw
}

.learnPageWrapper.mobile {
  display: flex;
  flex-direction: column;
  width: 100%
}

.learnPage {
  display: grid;
  grid-area: page;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto auto;
  gap: 0px 0px;
  grid-template-areas:
  "learnFilters"
  "titleRelations"
  "learnRelations"
  "toolbar";

}

.discussPage.desktop {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 60vw
}

.discussPage.mobile {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%
}
.discussionOverviewPage.desktop {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  width: 60vw
}

.discussionOverviewPage.mobile {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.overviewPage.desktop {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 85vw
}

.overviewPage.mobile {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%
}

.learnToolbar {
  grid-area: toolbar
}

.contributePage.mobile {
  width: 100%;
}

.contributePage.desktop {
  min-width: 800px;

}

.simplePage.mobile {
  display: flex;
  flex-direction: column;
  max-width: 100%;

}

.simplePage.desktop {
  display: flex;
  flex-direction: column;
  min-width: 600px;

}




.homePage.desktop {
  display: flex;
  flex-direction: row;

  align-items: center;

}

.homePage.mobile {
  display: flex;
  flex-direction: column;

  align-items: center;

}

#humandesktop {
  max-width: 50%;
  max-height: 660px;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
}

#humanmobile {
  width: 100%;
  height: fit-content;
  height: 400px;
}

#knowledgestatustextdesktop {
  max-width: 500px;
  margin: 0 auto;
  width: 50%;
}

#knowledgestatustextmobile {
  margin: 0 auto;
  width: 80%;
}

#hometextpagepartdesktop {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

#hometextpagepartmobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px
}

#hometextdesktop {
  max-width: 500px;
  margin: 0 auto;
}

#hometextmobile {
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px
}

.simplePageChildren {
  width: 100%;
  align-self: stretch;
}


.reviewPage {
  grid-area: page; 
}

.adminInsightsPage.desktop {
  grid-area: page; 
  display: flex;
  flex-direction: column;
  width: 85vw
}

.adminInsightsPage.mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.membersInsights {
  display: flex;
  flex-direction: column;
}

.explorePageWrapper.desktop {
  display: flex;
  flex-direction: column;
  width: 85vw
}

.explorePage.mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.explorePage.desktop {
  display: grid; 
  grid-template-columns: 6fr 4fr; 
  grid-template-rows: auto 1fr; 
  grid-template-areas: 
    "blogText blogText"
    "paneLeft paneRight"; 
  grid-area: page;
}

.blogText {  
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  overflow-x: hidden;
  grid-area: blogText;
  width: 90%;  
  font-size: 1em;
}

.paneLeft {  
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 0px 0px;
  grid-template-areas:

  "explorer"
;
  grid-area: paneLeft;
}

.exploreToolbar {
  grid-area: exploreToolbar;
  display: flex;

  flex-direction: row;
  gap: 3px;
  margin-left: 10px;
  margin-top: 10px;
  overflow-x: hidden;
  align-self: start;
  justify-self: stretch;
  height: 28px
}

.slider.desktop {
  grid-area: slider; 
}

.slider.mobile {
  align-self: stretch;
  min-width: 0;
  max-width: 100%;
}


.sliderdiv.box { 
  padding: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto; 
  grid-template-rows: 1fr 1fr; 
  grid-template-areas: 
    "slidertext slidertext slidertext"
    "minSliderValue slider maxSliderValue";
  gap: 20px
}

.slidertext { 
  grid-area: slidertext
}

.minSliderValue { 
  grid-area: minSliderValue
}

.maxSliderValue { 
  grid-area: maxSliderValue
}

.slider { 
  grid-area: slider
}

.searcher.desktop { grid-area: searcher; }
.searcher.mobile {
  align-self: stretch;
  min-width: 0;
  max-width: 100%;
}

.exploretippane.mobile {
  align-self: stretch;
  min-width: 0;
  max-width: 100%;
}

.exploretippane.desktop {
  grid-area: "exploretippane"
}

.explorer.desktop { grid-area: explorer;  
}

.explorer.mobile {
  align-self: stretch;
  min-width: 0;
  max-width: 100%;
}

.paneRight {  
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto auto auto auto;
  gap: 0px 0px;
  grid-template-areas:
    "exploreToolbar"
    "searcher"
    "exploretippane"
    "info"
    "slider"
    "relationRefFilter"
    "overviewBox";
  grid-area: paneRight;
  align-content: start;
}

.relationRefFilter.desktop {
  grid-area: "relationRefFilter";
  max-width: 100%
}

.highlights.desktop { 
  grid-area: highlights; 
  max-width: 100%
}

.highlights.mobile {
 align-self: stretch;
 padding: 0;
 margin: 0;
 min-width: 0;
 max-width: 100%;
}

.info.desktop { 
  grid-area: info; 
}

.info.mobile {
  align-self: stretch;
  min-width: 0;
  max-width: 100%;
}


.titleRelations{
  grid-area: "titleRelations";
  margin: 10px 10px 0px 10px;
}

.titleConcepts{
  grid-area: "titleConcepts";
  margin: 10px 10px 0px 10px;
}

.learnFilters {
  display: flex;
  grid-area: learnFilters;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  padding: 30px 10px 20px 20px;
}

.contributeFilters {
  display: flex;
  grid-area: learnFilters;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 10px 20px 10px;
}

.learnConcepts {
  grid-area: learnConcepts;
  display: flex;
  flex-direction: row;
  height: 310px;
  overflow-x: auto;
  padding-bottom: 5px;
  padding: 10px 10px 0px 10px;
}

.learnRelations {
  grid-area: learnRelations;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 5px;
  overflow-x: auto;
  padding: 10px 10px 0px 10px;
}

.learnRelations.mobile {
  grid-area: learnRelations;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 5px;
  overflow-x: auto;
  margin: 10px 10px 0px 10px;
}



.overviewFilters {
  grid-area: overviewFilters;
  max-width: 800px;
  margin: 10px 10px 0px 20px;
}


.overviewCardList {
  grid-area: overviewCardList;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 5px;
  overflow-x: auto;
  margin: 10px 10px 0px 10px;
  width: 100%
}

.overviewCardList.mobile {
  grid-area: overviewCardList;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 5px;
  overflow-x: auto;
  margin: 10px 10px 0px 10px;
}


.box {
   border: 1px solid #DEDDDD;
   border-radius: 5px;
   padding: 0;
   margin: 0;
   padding-left: 10px;
   padding-right: 20px;
   padding-bottom: 5px;
   margin: 10px 10px 10px 10px;

   vertical-align: top;
   box-shadow: 2px 2px 2px #A0CCD4, -2px -2px 2px #fff;

}

.box.noborder {
  border: 0px solid #c4d4cb;
  box-shadow: 2px 2px 2px #FFF, -2px -2px 2px #fff;
  border-radius: 0;
}

.paddingtop {
  padding-top: 10px
}

.searchOptions {
   background-color: white;
   font-family: "Open Sans", sans-serif;
  
}

.dialogWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
}

.dialogTitle {
  padding-left: 10px;
  padding-top: 10px;
  font-size: 1.4em;
  color: #4B7C8F;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  }

h1, .title{
  font-weight: bold;
  color: #985422;
  text-decoration-color: #78191A;
  font-size: 1.5em;
}

h2 {

  font-size: 1.2em;
  color: #2E2E30;
  font-weight: bold;
  margin-top: 5px
}
.knowledgeType {

  font-size: 1.1em;
  color: #2E2E30;
  font-weight: normal;
  margin: 0
}
h6 {

  font-size: 1.2em;
  color: black;
}
p
{
  
  text-align:left;
  padding-left: 10px;
}


table
{
  color:black;
  padding: 10px
}




input[type=submit], button, button:visited, .btn, .btn:visited {
  background-color: #4B7C8F;
  font-family: 'Open Sans';
  font-size: 1.2em;
  font-weight: bold;
  border: 1px solid #4B7C8F;
  border-radius: 5px;
  margin: 10px 5px 10px 5px;
  vertical-align: center;
  text-decoration: none;
  text-align: center;
  color: #E7E8F0;
  padding-right: 10px;
  padding-left: 10px
}

input[type=submit]:active, button:active, .btn:active, .btn.active {
  border: 1px solid #A0CCD4;
  background-color: #4B7C8F;
  color: #E7E8F0;

}
input[type=submit]:hover, button:hover, .btn:hover {
  border: 1px solid #A0CCD4;
  background-color: #4B7C8F;
  color: #E7E8F0;

}

button:disabled, .btnsmall:disabled {
  background-color: #A5A9B1;
  border-color: #A5A9B1;
}



input[type=submit], .btnsmall {
  min-width: unset;
  min-height: unset;
  min-height: 100%;
  justify-content: center;
  justify-items: center;
  align-items: center;
  vertical-align: middle;
  margin: 0;

  padding-right: 10px;
  padding-left: 10px
}

.btnsmall.active, .btnsmall:active {
  background-color: #4B7C8F;
  border: 1px solid #A0CCD4;
  color: #E7E8F0;

}

.btnsmall.highlight {
  border: 1px solid #A0CCD4;
  color: #E7E8F0;

}



.navigatorItem {
  margin: 0px 5px 0px 5px;
  text-align: left;
  max-height: 40px;
  color:#E7E8F0;
}

.grow1 { flex-grow: 1; }


input:focus, input:active {
  border: 1px solid #88A36C;
}

input[type=text].mine, input[type=password].mine, textarea, select, select:focus, .searchfield
{
  outline: none;
  width: 100%;
  padding: 12px 20px;
  margin: 6px 0px 10px 0px;
  box-sizing: border-box;
  border: 1px solid lightgray;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  background: #FFFFFF;
  resize: none;
  overflow-y: hidden;
}
input[type=text].mine:focus, input[type=password].mine:focus, textarea:focus, .searchfield:focus, input[type=text].mine:active, input[type=password].mine:active {
  border: 1px solid #A0CCD4 !important;
  margin: 6px 0px 10px 0px;
}

input[type=checkbox], input[type=radio] , input[type=checkbox]:focus, input[type=radio]:focus, input[type=checkbox]:active, input[type=radio]:active, input[type=checkbox]:hover, input[type=radio]:hover,  input[type=checkbox]:checked, input[type=radio]:checked {
  accent-color: #A0CCD4 !important;
  color: #A0CCD4;
  border: 1px solid #1D3C7F;
}


textarea.longtextarea 
{
  min-height: 150px
}

input[type=text].mine.shorttextfield
{
  width: 300px
}

label, label.mine, .label, .label.focused, .MuiFormLabel-root, .MuiFormLabel-root.focused, .MuiFormLabel-root.MuiFormLabel-filled, .MuiFormControlLabel-root, .MuiFormControlLabel-label {
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;

}
.MuiFormLabel-root, .MuiFormLabel-root.focused, .MuiFormLabel-root.MuiFormLabel-filled, .MuiFormControlLabel-root, .MuiFormControlLabel-label {
  color: #2E2E30 !important;
  font-family: 'Open Sans', sans-serif;
}

.radio 
{
  font-weight: 400 !important
}


.MuiSvgIcon-root {
  color: #A0CCD4;
  
}

a, .a
{
  color: #4B7C8F;
  border: 0;
  background: 0;
  font-family: 'Open Sans', sans-serif;
}
a:visited, .a:visited
{
  color: #4B7C8F;
  border: 0;
  background: 0;
  font-family: 'Open Sans', sans-serif;
}
a:active, .a:active
{
    color: #4B7C8F;
    border: 0;
    background: 0;
    font-family: 'Open Sans', sans-serif;
  }
a:hover, .a:hover
{
    color: #78191A;
    text-decoration: underline;
    border: 0;
    background: 0;
    font-family: 'Open Sans', sans-serif;
  }


.explanatory {
  font-family: 'Times New Roman', Times, serif !important
}
.stylish {
  font-family: 'Open Sans', sans-serif;
}
.forcebodyfont {
  font-family: 'Open Sans', sans-serif;
}
.forceninatokafont.ninatoka {
  font-family: 'Arizonia', Helvetica, Arial, sans-serif !important;
}
.forceninatokafont.tcm {
  font-family: 'Paprika', Helvetica, Arial, sans-serif !important;
}


.shift {
  margin-left: 10px;
}

.inputfield
{
  outline: none;
  width: 100%;
  padding: 12px 20px;
  margin: 6px 0;
  box-sizing: border-box;
  border: 1px solid #1D3C7F;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
}
.element 
{
  border: 1px solid #1D3C7F;
  border-radius: 10px;
  margin: 2px 2px;
  padding: 0px 2px;
  background-color: #E7E8F0;
}

.flexRowLayout
{
  display: flex;
  flex-flow: row wrap;
}

.logo
{
  height: 1em;

}

.knowledgeTypeIcon
{
  height: 0.8em;
  margin: 0
}

.textWhite {
  color: #E7E8F0
}

.textGreen {
  color: #A0CCD4
}

.textOrange {
  color: #78191A;
}

.alignright {
  float: right;
}

.indentright {
  padding-right: 10px;

}

.infoPane {
  min-width: 200;
  overflow: hidden;
  padding-top: 10px
}

.infoLearnPane {
  width: 400px;
  max-height: 500px;
  display: grid;
  grid-template-rows: 1fr auto;
  padding-top: 10px
}

.infoDiscussPane {
  width: 100%;
  max-height: 500px;
}


.overflow {
  min-width: 300px;
  overflow-y: auto;
  overflow-x: hidden
}

.box.maxwidth {
  width: 100%;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;

  width: 36px;
  height: 30px;
  left: 1%;
  top: 16px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #A0CCD4;

}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #78191A;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #A0CCD4;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.3em;

  background: #2E2E30;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #2E2E30;

}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  border: 0;
  align-self: center;
  justify-self: center;
}

/* Styling of overlay */
.bm-overlay {
  background: red;

}

.menu-item-split {
  font-size: 6px;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 80%;
  
}

.menu-item, .menu-item:visited 
{
  outline: none;
  text-align: center;
  color: #E7E8F0;
  font-family: 'Open Sans', sans-serif;
  align-self: center;
  text-decoration: none;
}

.menu-item:active, .menu-item.active,.menu-item:hover {
  outline: none !important;
  color: #A0CCD4;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  text-decoration: none;
}

.wordWrap {
    /* Warning: Needed for oldIE support, but words are broken up letter-by-letter */
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for webkit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.reference {
  font-style: italic;
  color: black;
  font-weight: light
}

.warning {
  color: gray;
}

table {
  max-width: 100%
}

.padding-table-columns td, th
{
    padding:0 20px 0 0; /* Only right padding*/
}

.loginButtons {
  float: right;
  font-size: 1em
}

.glyphicon.expand {
  margin: 1px 0px 0px 1px 
}

.glyphicon.trash {
  margin: 3px 1px 0px 0px 
}

.glyphicon.undo {
  margin: 1px 0px 0px 2px 
}

.glyphicon.redo {
  margin: 1px 2px 0px 0px 
}

.glyphicon.save {
  margin: 2px 0px 0px 2px 
}

.glyphicon.edit {
  margin: 2px 0px 0px 1px 
}

.glyphicon.highlight {
  margin: 3px 2px 0px 0px 
}

.glyphicon.add {
  margin: 2px 0px 0px 1px 
}

.glyphicon.comment {
  margin: 3px 1px 0px 1px 
}

.glyphicon.rateUp {
  margin: 3px 0px 0px 2px 
}

.glyphicon.rateDown {
  margin: 3px 0px 0px 2px 
}

.glyphicon.right {
  margin: 2px 0px 0px 0px
}

.glyphicon.send {
  margin: 0px 5px 0px 0px
}


.commentForm {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  grid-template-areas:
  "commentText commentSend";
  gap: 8px
}

.commentText {
  grid-area: commentText;
  justify-self: baseline;
  border: none;
  margin-bottom: none;
  padding-bottom: none
}

.commentSend {
  grid-area: commentSend;
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  padding-bottom: none
}

.getstartedpage.desktop {
  display: flex;
  width: 100vw;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  padding-left: 20px;
  padding-right: 20px;
  gap: 8px;
  padding-top: 40px;
}

.getstartedpage.mobile {
  display: flex;
  width: 100vw;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  padding-left: 20px;
  padding-right: 20px;
  gap: 8px;
  padding-top: 40px
}

.subscribePage.desktop {
  display: flex;
  flex-direction: column;

  align-items: center;

  padding-top: 40px
}

.subscribePage.mobile {
  display: flex;
  flex-direction: column;


  align-items: center;

  padding-top: 40px
}





#subscribePriceTabledesktop {
  max-width: 500px;
  align-self: center;
  align-content: center;
}

#subscribePriceTablemobile {
  margin: 0 auto;
  width: 80%;
}

#subscribehorizontal {
  display: flex;
  flex-direction: row;


  align-items: center;
  justify-content: space-around;
  padding: 20px;
  gap: 20px;
}

#subscribevertical {
  display: flex;
  flex-direction: column;


  align-items: center;
  justify-content: space-around;
  padding: 20px
}

.knowledgestatuspage.desktop {
  display: flex;
  flex-direction: column;


  align-items: center;

  padding-top: 40px
}

.knowledgestatuspage.mobile {
  display: flex;
  flex-direction: column;


  align-items: center;

  padding-top: 40px
}


.knowledgestatuspagecontent.desktop {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-around;
  padding: 20px

}

.knowledgestatuspagecontent.mobile {
  display: flex;
  flex-direction: column;

  width: 85vw;
  align-items: center;
  justify-content: space-around;
  padding: 20px
}

.chart.desktop {
  width: 600px;
  padding: 20px;
  margin: 0
}

.chart.mobile {
  margin: 0;
  max-width: 400px;
  padding: 20px;
  padding-left: 20px;

}


.chartMaxWidth.desktop {
  width: 400px;
  margin-top: 20px;
  margin-right: 20px
}

.video.desktop {
  min-width: 800px;
}

.video.mobile {
  max-width: 100%;
  min-width: 350px
}

.video-responsive {

  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.rc-slider-handle {
  border: solid 2px #1D3C7F !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #1D3C7F !important;
  box-shadow: 0 0 0 5px #c4d4cb !important;
}

.rc-slider-handle:focus-visible {
  border-color: #1D3C7F !important;
  box-shadow: 0 0 0 3px #c4d4cb !important;
}

textarea.comment:focus, textarea.comment {
  border: none !important;
  resize: none;
  overflow-y: hidden;
  margin-top: 10px;
}

textarea.comment.read:focus, textarea.read.comment, textarea.comment.read:disabled {
  border: none !important;
  resize: none;
  overflow-y: hidden;
  margin-top: 10px;
  padding: 0 0 0 0;
  color: black !important
}


.toolbox {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 3px;
  padding: 10px;
  overflow-x: hidden;
}

.toolbox.commentToolbox {
  float: right;
}

.toolbox.dialogToolbox {
  padding-right: 0;
  margin-right: 0;
  float: right;
}

.toolbox.gotoCommentsToolbox {
  margin-top: 10px;
  margin-right: 0px;
  padding: 0px;
  float: right;
}

.box.commentBox {
  padding-bottom: 0px;

}

.box.commentBoxNoBorder {
  padding-bottom: 0px;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
  border-bottom-width: 1px;
  border-color: #c4d4cb
}

.newlines {
  white-space: pre-line;
}

.columnWidth {
  min-width: 100px
}

.platformhome.ninatoka {
  font-family: 'Arizonia', Helvetica, Arial, sans-serif;
  font-size: 68px;
  color: #A0CCD4;
  padding-bottom: 20px;
  padding-left: 0 
}
.platformhome.tcm {
  font-family: 'Paprika', Helvetica, Arial, sans-serif;
  font-size: 4em;
  padding-bottom: 30px;
  padding-left: 0;
  margin: 0
}

.homebutton, .homebutton:active {
  flex: 1;
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: rgb(46,46,48);
  background: linear-gradient(0deg, rgba(46,46,48,1) 50%, rgba(222,221,221,1) 100%);
  box-shadow: 2px 2px 2px #aaa, -2px -2px 2px #fff;

}

.homebutton:hover {
  background: rgb(46,46,48);
  background: linear-gradient(0deg, rgba(46,46,48,0.6212749748336834) 50%, rgba(222,221,221,0.04704528295693278) 100%);
  border: none;
}

#authbuttonshome {
  display: flex;

}


#social {
  display: flex
}

#titleexplorer, #titlelogin, #titlelearn, #titlediscussoverview, #titleoverviews, #titlecontribute, #titlemergeconcepts, #titleaddroles, #titleadmininsights, #titleaccount, #titlepasswordreset, #titlegetstarted, #titleknowledgestatus {
  padding-left: 10px
}

.overviewBox.desktop {
  grid-area: overviewBox
}

.overviewBox.mobile {
  align-self: stretch;
  min-width: 0;
  max-width: 100%;
}

#admininsightsfilter {
  max-width: 600px;
  height: 20px;
  margin-left: 10px
}

.pricingtableimgbutton {
  background-color: #FFFFFF;
}

#pricingtableimg {
  max-width: 80%;;
}



.language-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 9999;
}

.language-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 4px;
  margin: 0;
  font-family: sans-serif;
  font-size: 14px;
  color: #333;
}

.language-code {
  font-weight: bold;
}

.language-code.selected {
  text-decoration: underline;
}


.language-name {
  margin-top: 5px;
}

.switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
}

.switch input {
  display: none;
}

.switch-slider {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
  border-radius: 8px;
  margin-right: 8px;
  cursor: pointer;
  background-color: #ccc;
  transition: background-color 0.4s;
}

.switch-slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.4s;
}

.switch input:checked + .switch-slider {
  background-color: #4B7C8F;
}

.switch input:checked + .switch-slider:before {
  transform: translateX(14px);
}

.switch-text {
  font-weight: normal;
}

.popupdesktop-content {
  margin: auto;
  background: rgb(255, 255, 255);
  max-width: 50%;
  padding: 5px;
  width: fit-content;
}

.popupmobile-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: fit-content;
  padding: 5px;
}